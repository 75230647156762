import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { BiPencil, BiTrash } from "react-icons/bi";
import { HiOutlineEye } from "react-icons/hi";
import { debounce } from "lodash";
import { notification } from "antd";

import { MASTER_DATA_URL } from "services/config";
import { BasicList } from "components/ListPage";
import { ConfirmationModal } from "components/Modal";
import http from "app/interceptors";

const ThematicProgrammes = () => {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginator, setPaginator] = useState();
  const [loading, setLoading] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const links = [
    {
      path: "/thematic-programmes",
      label: "Thematic Programmes",
    },
    {
      label: "Thematic Programmes",
    },
  ];

  const loadDataTP = () => {
    setLoading(true);

    let path = `${MASTER_DATA_URL}/ThematicProgrammes/datatable`;
    let payload = {
      generalSearch: keyword,
      searches: [
        {
          field: "",
          value: "",
        },
      ],
      sorts: [
        {
          field: "",
          order: "",
        },
      ],
      pageNumber: page,
      pageSize: limit,
    };

    http.post(path, payload).then((res) => {
      setThematicProgrammes(res.data?.data?.data);
      setPaginator({
        page: res.data?.data?.currentPage,
        limit: res.data?.data?.pageSize,
        total: res.data?.data?.totalCount,
        previous_pages: res.data?.data?.currentPage > 1,
        next_pages: res.data?.data?.currentPage < res.data?.data?.totalPages,
      });
      setLoading(false);
    });
  };

  useMemo(() => {
    loadDataTP();
  }, [keyword, page, limit]);

  const deleteDataTP = (id) => {
    // setLoading(true);
    http
      .delete(`${MASTER_DATA_URL}/ThematicProgrammes?id=${id}`)
      .then((res) => {
        notification.success({
          message: "Berhasil",
          description: "Berhasil menghapus data Themmatic Programmes!",
        });
        loadDataTP();
        setIsOpen(false);
      })
      .catch((err) => {
        notification.error({
          message: "Kesalahan",
          description: err.message,
        });
        setIsOpen(false);
      });
  };

  const [thematicProgrammes, setThematicProgrammes] = useState([]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const onDelete = (data) => {};

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "thematicProgrammes",
        title: "Thematic Programmes",
        dataIndex: "nama",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "description",
        title: "Deskripsi",
        dataIndex: "deskripsi",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Action",
        dataIndex: "actions",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        // columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full">
            <HiOutlineEye
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate(`/thematic-programmes/detail/${data.id}`)}
            />
            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate(`/thematic-programmes/form/${data.id}`)}
            />
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => {
                setIsOpen(true);
                setDeleteId(data?.id);
              }}
            />
          </div>
        ),
      },
    ];
  }, [thematicProgrammes]);

  return (
    <>
      <BasicList
        links={links}
        title={"Thematic Programmes"}
        onCreate={() => navigate("/thematic-programmes/form")}
        onSearch={onSearch}
        columns={tableColumns}
        dataSource={thematicProgrammes}
        isLoading={loading}
        onChangePage={setPage}
        onChangeRowsPerPage={setLimit}
        pagination={paginator}
      />
      <ConfirmationModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setDeleteId(null);
        }}
        onSubmit={() => deleteDataTP(deleteId)}
      ></ConfirmationModal>
    </>
  );
};

export default ThematicProgrammes;
