import React, { useCallback, useMemo, useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { HiOutlineEye } from "react-icons/hi";
import { useNavigate } from "react-router";
import { debounce, map } from "lodash";
import { notification } from "antd";

import { MASTER_DATA_URL } from "services/config";
import { BasicList } from "components/ListPage";
import { ConfirmationModal } from "components/Modal";
import http from "app/interceptors";

const IndicativeActivity = () => {
  const navigate = useNavigate();
  
  const [indicative, setIndicative] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginator, setPaginator] = useState();
  const [loading, setLoading] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const links = [
    {
      path: "/thematic-programmes",
      label: "Thematic Programmes",
    },
    {
      label: "Indicative Activity",
    },
  ];

  const loadDataIndicative = () => {
		setLoading(true);

		let path = `${MASTER_DATA_URL}/IndicativeActivity/datatable`

		let payload = {
      "generalSearch": keyword,
      "searches": [
        {
          "field": "",
          "value": ""
        }
      ],
      "sorts": [
        {
          "field": "",
          "order": ""
        }
      ],
      "pageNumber": page,
      "pageSize": limit
    }

		http.post(path, payload)
			.then(res => {
				setIndicative(res.data?.data?.data);
        
				setPaginator({
          page: res.data?.data?.currentPage,
          limit: res.data?.data?.pageSize,
          total: res.data?.data?.totalCount,
          previous_pages: res.data?.data?.currentPage > 1,
          next_pages: res.data?.data?.currentPage < res.data?.data?.totalPages,
        });
				setLoading(false);
			});
	};

	useMemo(() => {
		loadDataIndicative();
	}, [keyword, page, limit])

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const onDelete = (id) => {
    setLoading(true);
    http.delete(`${MASTER_DATA_URL}/IndicativeActivity?id=${id}`)
      .then(res => {
        notification.success({
          message: "Berhasil",
          description: "Berhasil menghapus data Indicative Activity!"
        });
        loadDataIndicative();
        setLoading(false);
        setIsOpen(false);
      })
      .catch((err) => {
        notification.error({
          message: "Kesalahan",
          description: err.message
        });
        setLoading(false);
        setIsOpen(false);
      })
  };

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "indicativeActivity",
        title: "Indicative Activity",
        dataIndex: "nama",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "sub_indicative_activity",
        title: "Sub Indicative Activity",
        dataIndex: "sub_indicative_activity",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data) => (
          <div>
            {map(data?.subIndicativeActivities ?? [], (item, key) => (
              <div key={key} className="flex items-center space-x-2">
                <div>{item?.name}</div>
              </div>
            ))}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Action",
        dataIndex: "actions",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        // columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full">
            <HiOutlineEye
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate(`/thematic-programmes/indicative-activity/detail/${data.id}`)}
            />
            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate(`/thematic-programmes/indicative-activity/form/${data.id}`)}
            />
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => {
                setIsOpen(true);
                setDeleteId(data?.id);
              }}
              
            />
          </div>
        ),
      },
    ];
  }, [indicative]);

  return (
    <>
    <BasicList
      links={links}
      title={"Indicative Activity"}
      onCreate={() => navigate("/thematic-programmes/indicative-activity/form")}
      onSearch={onSearch}
      columns={tableColumns}
      dataSource={indicative}
      isLoading={loading}
      onChangePage={setPage}
      onChangeRowsPerPage={setLimit}
      pagination={paginator}
    />
    <ConfirmationModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setDeleteId(null);
        }}
        onSubmit={() => {
          onDelete(deleteId);
        }}
      ></ConfirmationModal>
    </>
  );
};

export default IndicativeActivity;
