import SigninCallback from "pages/SigninCallback";
import Signout from "pages/Signout";
import SignoutCallback from "pages/SignoutCallback";
import SilentRenew from "pages/SilentRenew";

const routesGuest = [
  {
    routeType: "guest",
    path: "/signin-oidc",
    element: SigninCallback,
    exact: true,
  },
  {
    routeType: "guest",
    path: "/silentrenew",
    element: SilentRenew,
    exact: true,
  },
  {
    routeType: "guest",
    path: "/signout-oidc",
    element: SignoutCallback,
    exact: true,
  },
  {
    routeType: "guest",
    path: "/logout",
    element: Signout,
    exact: true,
  },
];

export default routesGuest;
