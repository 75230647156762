import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: localStorage.getItem("user"),
  isLoadingUser: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    storeUser: (state, action) => {
      state.isLoadingUser = false;
      state.user = action?.payload;
    },
    loadingUser: (state) => {
      state.isLoadingUser = true;
    },
    logoutUser: (state) => {
      state.isLoadingUser = false;
      state.user = null;
    },
  },
});

// this is for dispatch
export const { storeUser, loadingUser, logoutUser } = authSlice.actions;

// this is for configureStore
export default authSlice.reducer;
