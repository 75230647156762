import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { notification } from "antd";

import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Label,
} from "components";
import { MASTER_DATA_URL } from "services/config";
import http from "app/interceptors";

const IndicativeActivityDetail = () => {
  const params = useParams();
  const links = [
    {
      path: "/thematic-programmes",
      label: "Thematic Programmes",
    },
    {
      path: "/thematic-programmes",
      label: "Indicative Activity",
    },
    {
      label: "Detail",
    },
  ];

  useEffect(() => {
    if (params.id) {
      loadDetailDataTP();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dataIndicative, setDataIndicative] = useState();
  const loadDetailDataTP = () => {
    // setLoading(true);
    http
      .get(`${MASTER_DATA_URL}/IndicativeActivity/${params.id}`)
      .then((res) => {
        let tp = res.data?.data;
        setDataIndicative(tp);
      })
      .catch((err) => {
        // setLoading(false);
        notification.error({
          message: "Kesalahan",
          description: err.message,
        });
      });
  };

  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      <Card>
        <CardHeader>
          <div>Detail</div>
        </CardHeader>
        <CardBody>
          <div className="space-y-5">
            <div className="space-y-1">
              <Label>Indicative Activity</Label>
              <p className="text-sm text-gray-800">{dataIndicative?.nama}</p>
            </div>
            <div className="space-y-1">
              <Label>Deskripsi</Label>
              <p className="text-sm text-gray-800">
                {dataIndicative?.deskripsi}
              </p>
            </div>
            <div className="space-y-2">
              <Label>Daftar Sub Indicative Activity</Label>
              <table className="table w-full text-gray-600">
                <thead className="border-b">
                  <tr>
                    <th className="bg-primary-50">No</th>
                    <th className="bg-primary-50">Sub Indicative Activity</th>
                    <th className="bg-primary-50">Deskripsi</th>
                  </tr>
                </thead>
                <tbody>
                  {dataIndicative?.subIndicativeActivities?.map((item, idx) => (
                    <tr className="border-b">
                      <td>{idx + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default IndicativeActivityDetail;
