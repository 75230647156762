import { RiFileSearchLine } from 'react-icons/ri';

const MENU_ITEMS = [
  {
    icon: <RiFileSearchLine className="text-white w-[18px] h-[20px]" />,
    id: 'theory-of-change',
    label: 'Theory of Change',
    path: '/theory-of-change',
    child: [
      {
        id: 'impact',
        label: 'Impact',
        path: '/theory-of-change/impact',
      },
      {
        id: 'outcome',
        label: 'Outcome',
        path: '/theory-of-change/outcome',
      },
      {
        id: 'output',
        label: 'Output',
        path: '/theory-of-change/output',
      },
      {
        id: 'indicator',
        label: 'Indicator',
        path: '/theory-of-change/indicator',
      },
    ],
  },
  {
    icon: <RiFileSearchLine className="text-white w-[18px] h-[20px]" />,
    id: 'thematic-programmes',
    label: 'Thematic Programmes',
    path: '/thematic-programmes',
    child: [
      {
        id: 'thematic-programmes',
        label: 'Thematic Programmes',
        path: '/thematic-programmes',
      },
      {
        id: 'indicative-activity',
        label: 'Indicative Activity',
        path: '/thematic-programmes/indicative-activity',
      },
    ],
  },
  // {
  //   icon: <RiFileSearchLine className="text-white w-[18px] h-[20px]" />,
  //   id: 'investment-strategy',
  //   label: 'Investment Strategy',
  //   path: '/investment-strategy',
  //   child: [
  //     {
  //       id: 'source-of-funds',
  //       label: 'Source of Funds',
  //       path: '/investment-strategy/source-of-funds',
  //     },
  //     {
  //       id: 'investment-criteria',
  //       label: 'Investment Criteria',
  //       path: '/investment-strategy/investment-criteria',
  //     },
  //     {
  //       id: 'financial-instrument',
  //       label: 'Financial Instrument',
  //       path: '/investment-strategy/financial-instrument',
  //     },
  //   ],
  // },
  // {
  //   icon: <RiFileSearchLine className="text-white w-[18px] h-[20px]" />,
  //   id: 'master-data',
  //   label: 'Master Data',
  //   path: '/master-data',
  //   child: [
  //     {
  //       id: 'intervention',
  //       label: 'Fokus Isu/Intervensi Lembaga',
  //       path: '/master-data/intervention',
  //     },
  //     {
  //       id: 'cooperation-area',
  //       label: 'Bidang Kerjasama',
  //       path: '/master-data/cooperation-area',
  //     },
  //     {
  //       id: 'institution-type',
  //       label: 'Jenis Lembaga',
  //       path: '/master-data/institution-type',
  //     },
  //     {
  //       id: 'strategic-plan',
  //       label: 'Rencana Strategis',
  //       path: '/master-data/strategic-plan',
  //     },
  //     {
  //       id: 'capacity-assessment',
  //       label: 'Penilaian Kapasitas',
  //       path: '/master-data/capacity-assessment',
  //     },
  //     {
  //       id: 'financial-ratio-value',
  //       label: 'Nilai Ratio Keuangan',
  //       path: '/master-data/financial-ratio-value',
  //     },
  //     {
  //       id: 'main-activity',
  //       label: 'Kegiatan Utama',
  //       path: '/master-data/main-activity',
  //     },
  //     {
  //       id: 'detail-activity',
  //       label: 'Detail Kegiatan',
  //       path: '/master-data/detail-activity',
  //     },
  //     {
  //       id: 'budget-account',
  //       label: 'Mata Anggaran',
  //       path: '/master-data/budget-account',
  //     },
  //   ],
  // },
  // {
  //   icon: <RiFileSearchLine className="text-white w-[18px] h-[20px]" />,
  //   id: 'user-management',
  //   label: 'User Management',
  //   path: '/user-management',
  //   child: [
  //     {
  //       id: 'internal',
  //       label: 'User Internal',
  //       path: '/user-management/internal',
  //     },
  //     {
  //       id: 'external',
  //       label: 'User External',
  //       path: '/user-management/external',
  //     },
  //     {
  //       id: 'activity-log',
  //       label: 'Log Aktivitas',
  //       path: '/user-management/activity-log',
  //     },
  //     {
  //       id: 'group-management',
  //       label: 'Management Group',
  //       path: '/user-management/group-management',
  //     },
  //     {
  //       id: 'level-management',
  //       label: 'Management Level',
  //       path: '/user-management/level-management',
  //     },
  //     {
  //       id: 'menu-management',
  //       label: 'Management Menu',
  //       path: '/user-management/menu-management',
  //     },
  //     {
  //       id: 'app-access-management',
  //       label: 'Management Akses Aplikasi',
  //       path: '/user-management/app-access-management',
  //     },
  //   ],
  // },
];

export default MENU_ITEMS;
