import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import _ from "lodash";

import { BasicLayout } from "components/Layout";
import My404Component from "pages/My404Component";

import route from "./route";

const MasterRoutes = () => {
  const routesGuest = _.filter(
    route,
    ({ routeType }) => routeType !== "public"
  );
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/theory-of-change/impact" replace />}
        />

        {/* Guest routes */}
        {_.map(routesGuest, (val, key) => (
          <Route
            key={key}
            path={val.path}
            exact={true}
            element={<val.element />}
          />
        ))}

        {/* Protected routes */}
        <Route element={<BasicLayout />}>
          {_.map(_.filter(route, { routeType: "public" }), (val, key) => (
            <Route
              exact={true}
              key={key}
              path={val.path}
              element={<val.element />}
            />
          ))}
        </Route>

        <Route path="*" element={<My404Component />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MasterRoutes;
