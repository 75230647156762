import React, { useEffect } from "react";

import { useAuthContext } from "providers/authProviders";

const Signout = () => {
  const { logout } = useAuthContext();

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <p className="text-4xl text-center font-bold mb-1">Sedang memproses...</p>
    </div>
  );
};

export default Signout;
