import React, { useEffect, useState } from "react";
import { CgArrowLeft } from "react-icons/cg";
import { useNavigate, useParams } from "react-router";
import { map } from "lodash";
import { notification } from "antd";

import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Label,
  TitleText,
} from "components";
import { MASTER_DATA_URL } from "services/config";
import http from "app/interceptors";

const OutputDetail = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [dataOutput, setDataOutput] = useState();

  useEffect(() => {
    if (params.id) {
      loadDetailDataOutput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDetailDataOutput = () => {
    // setLoading(true);
    http
      .get(`${MASTER_DATA_URL}/Output/${params.id}`)
      .then((res) => {
        let output = res.data?.data;
        setDataOutput(output);
      })
      .catch((err) => {
        // setLoading(false);
        notification.error({
          message: "Kesalahan",
          description: err.message,
        });
      });
  };

  const links = [
    {
      path: "/theory-of-change",
      label: "Theory of Change",
    },
    {
      path: "/theory-of-change/output",
      label: "Output",
    },
    {
      label: "Detail",
    },
  ];
  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      <span
        className="flex items-center space-x-2 cursor-pointer text-gray-600"
        onClick={() => navigate(-1)}
      >
        <CgArrowLeft className="text-lg" />
        <p className="font-bold text-sm">Back to Output</p>
      </span>
      <Card>
        <CardHeader>
          <div>Detail</div>
        </CardHeader>
        <CardBody>
          <div className="space-y-5">
            <div className="flex items-center justify-between">
              <div className="w-1/2 space-y-1">
                <Label>Output</Label>
                <p className="text-sm text-gray-800">{dataOutput?.nama}</p>
              </div>
              <div className="w-1/2 space-y-1">
                <Label>Format Penomoran</Label>
                <p className="text-sm text-gray-800">
                  {dataOutput?.numberingFormat}
                </p>
              </div>
            </div>
            <div className="space-y-1">
              <Label>Deskripsi</Label>
              <p className="text-sm text-gray-800">{dataOutput?.deskripsi}</p>
            </div>
            <div className="flex justify-between">
              <div className="w-1/2 space-y-1">
                <Label>Level</Label>
                <p className="text-sm text-gray-800">{dataOutput?.level}</p>
              </div>
              <div className="w-1/2 space-y-1">
                <Label>Full Code</Label>
                <p className="text-sm text-gray-800">
                  {dataOutput?.coaFullCode ?? ""}
                </p>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <div>Sub Output</div>
        </CardHeader>
        <CardBody>
          <div className="divide-y space-y-6">
            {map(dataOutput?.subOutputs, (subOutput, key) => (
              <div className={`space-y-5 ${key > 0 && "pt-3"}`} key={key}>
                <div className="flex items-center justify-between">
                  <div className="w-1/2 space-y-1">
                    <Label>Sub Output</Label>
                    <p className="text-sm text-gray-800">{subOutput.name}</p>
                  </div>
                  <div className="w-1/2 space-y-1">
                    <Label>Format Penomoran</Label>
                    <p className="text-sm text-gray-800">
                      {subOutput.numberingFormat}
                    </p>
                  </div>
                </div>
                <div className="space-y-1">
                  <Label>Deskripsi</Label>
                  <p className="text-sm text-gray-800">
                    {subOutput.description}
                  </p>
                </div>
                <div className="flex justify-between">
                  <div className="w-1/2 space-y-1">
                    <Label>Level</Label>
                    <p className="text-sm text-gray-800">{subOutput?.level}</p>
                  </div>
                  <div className="w-1/2 space-y-1">
                    <Label>Full Code</Label>
                    <p className="text-sm text-gray-800">
                      {subOutput?.coaFullCode ?? ""}
                    </p>
                  </div>
                </div>
                <div className="space-y-5">
                  <TitleText className="text-primary-700 font-bold">
                    Indicator
                  </TitleText>
                  <div className="overflow-auto">
                    <table className="table w-full text-gray-600">
                      <thead className="border-b">
                        <tr>
                          <th className="bg-primary-50">No</th>
                          <th className="bg-primary-50">Indicator</th>
                          <th className="bg-primary-50">Deskripsi</th>
                          <th className="bg-primary-50">Parameter</th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(subOutput?.indicators, (indicator) => (
                          <>
                            <tr className="border-b">
                              <td className="sticky left-0">
                                {indicator?.numberingFormat ?? "-"}
                              </td>
                              <td>{indicator?.nama ?? "-"}</td>
                              <td>{indicator?.deskripsi ?? "-"}</td>
                              <td>
                                {indicator?.parameter?.length > 0
                                  ? map(
                                      indicator?.parameter,
                                      (parameter, index, { length }) => {
                                        return (
                                          <div>
                                            {parameter.nama} ({parameter.satuan}
                                            )
                                          </div>
                                        );
                                      }
                                    )
                                  : "-"}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default OutputDetail;
