import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import { getUserDetail } from "services/userManagement";
import { Header, Sidebar, Spinner } from "components";
import { useAuthContext } from "providers/authProviders";
import routesName from "routes/route";

const BasicLayout = () => {
  const { isAuthenticated, signinRedirect } = useAuthContext();
  const { user } = useSelector(({ auth }) => auth);
  const location = useLocation();

  const { refetch: fetchUserDetail } = useQuery({
    queryKey: ["user", user?.UserId],
    queryFn: () => getUserDetail(user?.UserId),
    enabled: false,
  });

  const [isChecking, setChecking] = useState(true);
  const [isAuth, setAuthenticated] = useState(false);
  const [isAuthorize, setAuthorized] = useState(false);

  const checkAuthentication = async () => {
    const resIsAuthenticated = await isAuthenticated();
    setAuthenticated(resIsAuthenticated);

    if (!resIsAuthenticated) {
      throw new Error("user unauthenticate");
    }
  };

  const checkAuthorization = async () => {
    const { data } = await fetchUserDetail(user.UserId);
    const userDetail = data?.data;

    if (userDetail?.userAccess && Array.isArray(userDetail.userAccess)) {
      const moduleMatched = userDetail.userAccess.find(
        (x) => x.moduleName?.toLowerCase() === "master"
      );

      // module not found
      if (!moduleMatched) {
        setAuthorized(false);
        return;
      }

      const userAccess = moduleMatched?.menuModules ?? [];
      const userAccessMapped = userAccess.reduce((obj, item) => {
        return Object.assign(obj, {
          [item.key]: { ...item },
        });
      }, {});

      // doesnt have access right
      if (!userAccess.length) {
        setAuthorized(false);
        return;
      }

      const { routeKey, routeAction } = routesName.find((route) => {
        return route.path === location.pathname;
      });

      const accessAuthorized = userAccessMapped[routeKey]?.[routeAction];
      setAuthorized(accessAuthorized);
    }
  };

  useEffect(() => {
    (async () => {
      setChecking(true);

      try {
        await checkAuthentication();
        await checkAuthorization();
      } catch (error) {
        console.log("auth validation failed:", error);
      } finally {
        setChecking(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, user]);

  useEffect(() => {
    if (!isChecking && !isAuth) {
      signinRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecking, isAuth]);

  if (isChecking || !isAuth) {
    return (
      <div className="relative min-h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="flex flex-1 overflow-hidden">
          <Sidebar />

          <div className="flex-1">
            <Header />
            <motion.main
              className="flex-1 overflow-y-auto paragraph p-8 pt-[5rem] bg-gray-50 w-screen md:w-[calc(100vw-15rem)]"
              key={window.location.pathname}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.05 }}
            >
              {isChecking && (
                <div className="relative min-h-screen">
                  <Spinner />
                </div>
              )}

              {!isChecking && isAuthorize ? (
                <Outlet />
              ) : (
                <div className="flex flex-col items-center justify-center w-full h-screen">
                  <p className="text-center font-bold mb-1">
                    Anda tidak memiliki akses, hubungi admin.
                  </p>
                </div>
              )}
            </motion.main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicLayout;
