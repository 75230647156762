import React, { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { findIndex } from "lodash";
import { useNavigate, useParams } from "react-router";
import { notification } from "antd";

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "components";
import { FormModal } from "components/Modal";
import InformationForm from "./informationForm";
import SubIndicativeActivityForm from "./subIndicativeActivityForm";
import SubIndicativeActivityItem from "./subIndicativeActivityItem";
import { MASTER_DATA_URL } from "services/config";
import http from "app/interceptors";

const IndicativeActivityForm = () => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      loadDetailDataIndicative();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDetailDataIndicative = () => {
    // setLoading(true);
    http
      .get(`${MASTER_DATA_URL}/IndicativeActivity/${params.id}`)
      .then((res) => {
        let indicative = res.data?.data;
        methods.setValue("numberingFormat", indicative?.numberingFormat);
        methods.setValue("nama", indicative?.nama);
        methods.setValue("deskripsi", indicative?.deskripsi);
        methods.setValue(
          "subIndicativeActivities",
          indicative?.subIndicativeActivities
        );
      })
      .catch((err) => {
        // setLoading(false);
        notification.error({
          message: "Kesalahan",
          description: err.message,
        });
      });
  };

  const onFinishForm = () => {
    let payload = methods.getValues();
    payload.id = params.id;

    if (params.id) {
      http
        .put(`${MASTER_DATA_URL}/IndicativeActivity`, payload)
        .then((res) => {
          notification.success({
            message: "Berhasil",
            description: "Berhasil mengubah data Indicative Activity!",
          });
          navigate(-1);
        })
        .catch((err) => {
          // setLoading(false);
          notification.error({
            message: "Kesalahan",
            description: err.message,
          });
        });
    } else {
      http
        .post(`${MASTER_DATA_URL}/IndicativeActivity`, payload)
        .then((res) => {
          notification.success({
            message: "Berhasil",
            description: "Berhasil mengubah data Indicative Activity!",
          });
          navigate(-1);
        })
        .catch((err) => {
          // setLoading(false);
          notification.error({
            message: "Kesalahan",
            description: err.message,
          });
        });
    }
  };

  const methods = useForm({
    defaultValues: {
      format_number: "#0",
      subIndicativeActivities: [],
    },
  });
  const { append, update, remove, fields } = useFieldArray({
    control: methods?.control,
    name: "subIndicativeActivities",
  });

  const subIndicativeActivityMethods = useForm({
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const [subIndicativeActivityModal, setSubIndicativeActivity] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (!subIndicativeActivityModal) {
      setIsEdit(false);
      subIndicativeActivityMethods.reset({
        sub_indicative_activity: "",
        description: "",
      });
    }
    return;
  }, [
    methods.getValues("subIndicativeActivities"),
    subIndicativeActivityModal,
  ]);

  const links = [
    {
      path: "/thematic-programmes",
      label: "Thematic Programmes",
    },
    {
      path: "/thematic-programmes",
      label: "Indicative Activity",
    },
    {
      label: "Tambah Indicative Activity",
    },
  ];

  const onSubmitSubIndicativeActivity = (data) => {
    if (data) {
      if (isEdit) {
        const index = findIndex(fields, (field) => field.id === data.id);
        update(index, data);
      } else {
        append(data);
      }
      setSubIndicativeActivity(false);
    }
  };

  const onEditSubIndicativeActivity = (data) => {
    setSubIndicativeActivity(true);
    setIsEdit(true);
    subIndicativeActivityMethods.reset(data);
  };

  const onDeleteSubIndicativeActivity = (data) => {
    const index = findIndex(fields, (field) => field.id === data.id);
    remove(index);
  };

  return (
    <div>
      <FormModal
        title={
          isEdit
            ? "Edit Sub Indicative Activity"
            : "Tambah Sub Indicative Activity"
        }
        open={subIndicativeActivityModal}
        onClose={setSubIndicativeActivity}
        onSubmit={() =>
          subIndicativeActivityMethods.handleSubmit(
            onSubmitSubIndicativeActivity
          )()
        }
      >
        <FormProvider {...subIndicativeActivityMethods}>
          <SubIndicativeActivityForm />
        </FormProvider>
      </FormModal>
      <FormProvider {...methods}>
        <div className="space-y-6">
          <Breadcrumbs items={links} />
          <Card>
            <CardHeader>
              <div>Tambah Indicative Activity</div>
            </CardHeader>
            <CardBody>
              <InformationForm />
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <div className="flex items-center justify-between">
                <div>Sub Indicative Activity</div>
                <div>
                  <Button
                    className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                    startIcon={<AiOutlinePlus className="text-white" />}
                    onClick={() => setSubIndicativeActivity(true)}
                  >
                    Tambah Sub Indicative Activity
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <SubIndicativeActivityItem
                datas={fields}
                onEdit={onEditSubIndicativeActivity}
                onDelete={onDeleteSubIndicativeActivity}
              />
            </CardBody>
          </Card>
          <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-60"
              onClick={() => navigate(-1)}
            >
              Batal
            </Button>
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-60"
              onClick={() => {
                onFinishForm();
              }}
            >
              Simpan
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default IndicativeActivityForm;
